import { getUrlLastPart } from "./Utils/helpers";
import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  setActiveFooterTab,
  setActiveTab,
  showSideBar,
} from "../../store/actions";
import Hamburger from "./Hamburger";
import { logoutUser } from "../../store/Auth/actions";
import { withRouter } from "react-router-dom";
import { getUserBalance } from "./Utils/helpers";
import {BlockedGames} from "../../helpers/repository";

function Header(props) {
  const dispatch = useDispatch();
  const isLoggedIn = sessionStorage.getItem("token") ? true : false;
  const [show, setShow] = useState(false);
  const Products = useSelector((state) => state?.Products.Index ?? []);


  const handleLogout = (e) => {
    e.preventDefault();
    const { history } = props;
    dispatch(logoutUser(history));
  };

  useEffect(() => {
    BlockedGames().then((res) => {
      setIframeURL(res);
    }).catch((e) => {
      if(e?.response?.data?.code=="restrict"){
        setShow(true)
        // props.history.push("/comming-soon");
      }
    });
  }, []);

  const active_tab = useSelector(
      (state) => state?.CommonState?.activeTab ?? "home-upcoming"
  );
  let path = props?.location?.pathname.substring(1);
  let dataMenu = {
    Sports : {
      className:`${
          ["home-upcoming", "home-live-now"].includes(active_tab??'') ? "active" : ""
      }`,
      route:() => props.history.push("/home"),
      dispatch:() => dispatch(setActiveTab("home-upcoming")),
      footerActive: ''
    },
    AllSports : {
      className:`${
          ["AllSports", "sports-details", "Football"].includes(active_tab) ? "active" : ""
      }`,
      route:() => props.history.push("/AllSports"),
      dispatch:() => dispatch(setActiveTab("AllSports")),
      footerActive: () => dispatch(setActiveFooterTab(""))
    },
    Jackpot : {
      className:`${
          ["jackpot", "jackpot-running", "jackpot-upcoming"].includes(active_tab??'')
              ? "active"
              : ""
      }`,
      route:() => props.history.push("/jackpot"),
      dispatch:() => dispatch(setActiveTab("jackpot")),
      footerActive:() => setActiveFooterTab("")
    },
    Virtuals : {
      className:`${
          getUrlLastPart() == "virtual-league" ? "active" : ""
      }`,
      route:() => props.history.push("/virtual-league"),
      dispatch:() => dispatch(setActiveTab("virtual-league")),
      footerActive:() => dispatch(setActiveFooterTab(""))
    },
    Games : {
      className:`${active_tab == "Games" ? "active" : ""}`,
      route:() => props.history.push("/Games"),
      dispatch:() => dispatch(setActiveTab("Games")),
      footerActive:() => dispatch(setActiveFooterTab(""))
    },
    Promotions : {
      className:`${active_tab == "promotions" ? "active" : ""}`,
      route:() => props.history.push("/promotions"),
      dispatch:() => dispatch(setActiveTab("promotions")),
      footerActive:() => dispatch(setActiveFooterTab(""))
    },
    Casino : {
      className:`${active_tab == "casinos" ? "active" : ""}`,
      route:() => props.history.push("/casinos"),
      dispatch:() => dispatch(setActiveTab("casinos")),
      footerActive:() => dispatch(setActiveFooterTab(""))
    },
    Aviator : {
      className:`${
          ["Aviator", "aviator"].includes(active_tab??'')
              ? "active"
              : ""
      }`,
      route:() => props.history.push("/aviator"),
      dispatch:() => dispatch(setActiveFooterTab("")),
      footerActive: ''
    },
  }
  return (
      <div className="BLM-layout-header">
        <div className="BLM-mainHeader">
          <div className="BLM-mainHeader-leftSection">
            <div className="BLM-select BLM-selectSideMenu">
              <select
                  id="ddFeaturedSidemenu"
                  onChange="getSidemenuFeaturedphone(value)"
              >
                <option value>Menu</option>
                <option value="azsports">All Sport</option>
                <option value="promotions">Promotions</option>
                <optgroup label="Quick Links">
                  <option value="smsbetting">SMS Betting</option>
                  <option value="howtodeposit">How To Deposit</option>
                </optgroup>
              </select>
            </div>
            <div
                className="hamburger_menu"
                onClick={() => {
                  dispatch(showSideBar(true));
                }}
            >
              <Hamburger />
            </div>
            <div
                className="BLM-mainHeader-logo BLM-logo"
                onClick={() => {
                  dispatch(setActiveTab("home-upcoming"));
                  dispatch(setActiveFooterTab("home"));
                  props.history.push("/home");
                }}
            >
            
              <img src="/assets/images/PNG/BetIN_Alpha.png" />
            </div>
          </div>
          <div className="BLM-mainHeader-rightSection">
            <div className="BLM-mainHeader-beforeLogin ">
              <div className="BLM-mainHeader-loginRegister">
                {isLoggedIn ? (
                    <>
                      <Link to="/Deposits">
                    <span
                        class="BLM-betSlip-userBalance"
                        id="betslipUserBalance"
                    >
                      {["home","jackpot","virtual-league","Games","casinos","MyBets", "Deposits", "promotions","AllSports"].includes(path)
                          ? `${getUserBalance()}`
                          : null}
                    </span>
                        <button class="btn BLM-btn BLM-btnSuccess active BLM-btnSmall">
                          <span class="BLM-btnTxt">Deposit</span>
                        </button>
                      </Link>
                    </>
                ) : (
                    <>
                      <Link to="/login">
                        <button className="btn BLM-login BLM-btnSmall">
                          Login
                        </button>
                      </Link>
                      <Link to="/register">
                        <button className="btn BLM-register BLM-btnSmall">
                          Register
                        </button>
                      </Link>
                    </>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="BLM-depositMpesa-header">
          Deposit
          <div className="BLM-iconSvg">
            <svg
                version="1.1"
                baseProfile="tiny"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                xmlSpace="preserve"
            >
              <path d="M13.6,1.6l-1.8,1.8l7.3,7.3H0v2.6h19l-7.3,7.3l1.8,1.8L24,12L13.6,1.6z" />
            </svg>
          </div>{" "}
          MPESA Paybill: XXXXXX
        </div> */}
        <div
            className="BLM-subHeader BLM-scrollableArea BLM-horizontal"
            id="subHeader"
        >
          <ul className="topHeaderAddActiveClass">
            {  Products?.map((f) => {
              const foundData = dataMenu[f?.name.replace(/\s+/g, '')];
              if(foundData){
                return (
                    <>
                      <li
                          className={foundData?.className}
                          onClick={() => {
                            foundData?.route();
                            foundData?.dispatch();
                          }}
                      >
                        {f?.name}
                      </li>
                    </>
                );
              }
            })
            }
          </ul>
        </div>
      </div>
  );
}

export default withRouter(Header);
