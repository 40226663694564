import React, { useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getAllStaticPages } from "../../store/StaticPages/actions";
function ContentFooter(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllStaticPages());
  }, []);

  const PageTypes = useSelector((state) => state?.staticPages.Index ?? []).map(
    (sp) => sp["page_type"]
  );

  return (
    <>
      <div className="BLM-footer">
        <div className="BLM-footer-container">
          <div className="BLM-footer-logo"></div>
          <div className="BLM-footer-linksList">
            <ul>
              {PageTypes.map((pt, index) => (
                <li key={index}>
                  <Link to={pt?.replace(/ /g, "-")}>{pt}</Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="BLM-divider"></div>
          {/* <p>Betting License Placeholder</p> */}
          <p>Betting is addictive and can be psychologically harmful.</p>
          <p>
            Betin Prime is licensed and regulated by the National Lottery
            Administration of Ethiopia.
          </p>
          <p>You have to be 21+ years and above to bet</p>
        </div>
      </div>
    </>
  );
}

export default ContentFooter;
